<template>
    <div v-if="$store.getters.getLoaded" class="featured-properties">
       <flickity ref="flickity" :options="flickityOptions">
            <div class="carousel-cell" v-for="(property, i) in properties" :key="i">
                <img :src="url+property.DisplayImage.url" alt="">
                <span v-if="currentProperty.Status" class="tag">
                    {{currentProperty.Status | startCase}}
                </span>
            </div>
        </flickity>
        <div v-if="currentProperty" class="details">
            <h2>
                {{currentProperty.Address1}}
            </h2>
            <p>
                <span>{{currentProperty.Price | priceFormat}} {{ currentProperty.Lease ? '/mo.' : ''}} </span>
                <span class="desktop-only">&middot;</span>
                <br class="mobile-only">
                <span>{{currentProperty.Bedrooms + ' Bedrooms'}}</span>
                <span>&middot;</span>
                <span>{{currentProperty.Bathrooms + ' Bathrooms'}}</span>
            </p>
            <router-link class="btn" :to="`/property/${currentProperty.Slug}`">
                VIEW LISTING <img src="/images/icon/arrow.svg" alt="Read More" />
            </router-link>
        </div>
    </div>
</template>

<script>
import Flickity from 'vue-flickity';

export default {
    name: "FeaturedProperties",
    components: { Flickity},
    data(){
        return{
            url: 'https://strapi.uptowncreative.io',
            flickityOptions: {
                pageDots: false,
                wrapAround: true,
                isMounted: false,
                arrowShape: {
                    x0: 15,
                    x1: 60, y1: 50,
                    x2: 60, y2: 45,
                    x3: 20
                }
            },
            currentIndex: 0,
            intervalCounter: 0,
            myInterval: null,
        }
    },
    computed: {
        properties:function(){
            const homepageProperties = this.$store.getters.properties.filter(p => p.DisplayOnHomepage);
            const sortedHomepageProperties = homepageProperties.sort((a,b) => {
                if (a.HomepageDisplayOrder && b.HomepageDisplayOrder){
                    return a.HomepageDisplayOrder - b.HomepageDisplayOrder;
                }else if(a.HomepageDisplayOrder){
                    return 1;
                }else{
                    return -1;
                }
            })
            return sortedHomepageProperties;
        },
        currentProperty:function(){
            if(this.properties && this.properties.length){
                return this.properties[this.currentIndex];
            }else{
                return undefined;
            }
        }
    },
    mounted:function(){
        if(!this.$store.getters.loaded){
            this.myInterval = setInterval(() => {
                try{
                    this.$refs.flickity.on('select', (index) => {
                        this.currentIndex = index
                    })
                    this.intervalCounter++;
                    if(this.intervalCounter > 10){
                        clearInterval(this.myInterval)
                    }
                } catch {
                    console.log('errror on flicity');
                }
            }, 500)
        }
    },
}
</script>

<style lang="scss" scoped>
.carousel-cell{
    width: 70%;
    height: 70vh;
    
    margin: 10px 20px;
    img{
        display: block;
        width: 100%;
        height: 100%;
    }
    .tag{
        position: absolute;
        bottom: 20px;
        right: 50%;
        transform: translateX(50%);
    }
}

::v-deep .flickity-button {
  background: rgba(0,0,0,0.5);
  backdrop-filter: blur(3px);
}
::v-deep .flickity-button:hover {
  opacity: 0.7;
}

::v-deep .flickity-prev-next-button {
  width: 40px;
  height: 55px;
  border-radius: 0px;
}
/* icon color */
::v-deep .flickity-button-icon {
    top: 24%;
    height: 30px;
    fill: white;
}
/* position outside */
::v-deep .flickity-prev-next-button.previous {
  left: 0;
}
::v-deep .flickity-prev-next-button.next {
  right: 0;
}

.details{
    text-align: center;
    h2{
        font-size: 44.7273px;
        line-height: 45px;
        color: #000000;
        margin: 40px 0 20px;
        text-transform: uppercase;
    }
    p{
        font-weight: 275;
        font-size: 20px;
        line-height: 27px;
        letter-spacing: 0.07em;
        text-transform: uppercase;
        display: flex;
        gap: 5px;
        justify-content: center;
    }
    .btn{
        display: inline-block;
        margin: 20px 0 40px;
    }
}


@media (max-width: $tablet) {
    .carousel-cell{
        width: 100%;
        height: 80vh;
        max-height: 400px;
        margin: 0;
        img{
            display: block;
            width: 100%;
        }
        .tag{
            bottom: 60px;
        }
    }
    .details{
        margin: 15px;
        p{
            display:block;
            span{
                margin: 5px;
                line-height: 35px;
            }
        }
    }
    ::v-deep .flickity-prev-next-button {
        top: 100%;
    }
    ::v-deep .flickity-prev-next-button.previous{
        left: 34%;
    }
    ::v-deep .flickity-prev-next-button.next{
        right: 34%;
    }
}

@media (max-width: $mobile) {
    .carousel-cell{
        width: 100%;
        height: 40vh;
        max-height: 300px;
        margin: 0;
        img{
            display: block;
            width: 100%;
        }
        .tag{
            bottom: 50px;
        }
    }
    .details{
        margin: 15px;
        p{
            display:block;
            span{
                margin: 5px;
                line-height: 35px;
            }
        }
    }
}
</style>